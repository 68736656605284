// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/********** this file is for test and preprod build */
export const environment = {
    production: false,
    // videoResourceUrl: "https://pmb-reels.s3.ap-south-1.amazonaws.com/videos",
    // on boltseal Test Server
    socketUrl: "https://testsocket.pikmybox.com/",
    // socketUrl: "http://localhost:8443/",
    apiUrl: "/api/v1/", // for beta upload
    // apiUrl: "http://localhost:3001/api/v1/",  // for localhost to localhost
    // apiUrl: "https://beta.pikmybox.com/api/v1/",  // for localhost to beta

    p2pUrl: "https://beta3.pikmybox.com/",
    domesticUrl: "https://domesticpreprod.pikmybox.com",
    d2Url: "https://betad2.pikmybox.com/api/v1/",

    d2CrdForAuth: {
        "clientId": "extcl2345",
        "accessCode": "accesscode12345",
        "isExternalUser": true
    },

    showDelhivery: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
